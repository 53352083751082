import { Actions } from "utils/modal";
import { FormState } from "./constants";
import { HandleForm } from "./form";

interface Props {
  action: Actions;
  onClose: () => void;
  currentPlan?: FormState;
  onlyQuantity?: boolean;
  refreshCalendar?: () => void;
}

export const EditDialogContent = (props: Props) => {
  const { action, onClose, currentPlan, refreshCalendar, onlyQuantity } = props;
  return (
    <HandleForm
      action={action}
      onClose={onClose}
      initialValues={currentPlan}
      refreshCalendar={refreshCalendar}
      onlyQuantity={onlyQuantity}
    />
  );
};
