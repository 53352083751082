import { getCookie } from "utils/cookie";

export interface Images {
  background: string;
  logo: string;
}

export const getCompanyData = (companyId?: string): Images => {
  const company = !companyId ? getCookie("companyId") : companyId;
  const random = new Date().getTime();

  if (company) {
    return {
      background: `https://images-kos.s3.us-east-1.amazonaws.com/company/${company}_login_background.jpg?x=${random}`,
      logo: `https://images-kos.s3.us-east-1.amazonaws.com/company/${company}_login_logo.png?x=${random}`,
    };
  }

  return {
    background: "https://kitchen-os-assets.s3.amazonaws.com/images/login.jpg",
    logo: "",
  };
};
