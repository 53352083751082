import { KOSEmptyState, KOSTabs } from "@foodology-co/alejandria";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import HistoryToggleOffOutlinedIcon from "@mui/icons-material/HistoryToggleOffOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import PageBox from "app/components/PageBox";
import PageHeader from "app/components/PageHeader/PageHeader";
import ProductionCard from "app/components/Productions/Card";
import ProductionInfo from "app/components/Productions/DrawerInfo";
import TimeSlotFilter from "app/components/Productions/Filter/TimeSlotFilter";
import ProductionLabelCreate from "app/components/Productions/Label/Create";
import ProductionLabelHistoryDialog from "app/components/Productions/Label/HistoryDialog";
import ProductionScheduleCalendar from "app/components/Productions/Schedule/Calendar";
import {
  ProductionDialogProps,
  useHandleProductions,
} from "app/components/Productions/Schedule/Manager/useHandleProductions";
import ProductionScheduleNavigation from "app/components/Productions/Schedule/Navigation";
import ProductionUserManager from "app/components/Productions/UserManager";
import SearchOnTopBar from "app/components/common/SearchOnTopBar";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { useNavigator } from "app/hooks/useNavigator";
import { commons, kitchenInventoryManagement } from "app/i18n/types";
import {
  getProductionsByKitchen,
  getUsersByKitchenId,
} from "app/store/slices/productions/thunks";
import { User } from "core/account/entities/User";
import {
  RemoteConfigKey,
  getRemoteValueBoolean,
} from "core/common/utils/remoteConfig";
import { ProductionRecord } from "core/productions/entities/Productions";
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { compareSearchText } from "utils/general";
import { Actions } from "utils/modal";
import {
  SelectedProductionActionType,
  SelectedProductionAndAction,
} from "utils/ratatouille";
import {
  TypeOfRole,
  canProductionSchedule,
  canProductionScheduleOnlyView,
  isSuperRole,
} from "utils/role";

interface ProductionPageProps {}

const ProductionPage: FunctionComponent<ProductionPageProps> = () => {
  const navigator = useNavigator();
  const { kitchenId } = navigator.params();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const canCreateNewProductions = getRemoteValueBoolean(
    RemoteConfigKey.ALLOW_NEW_PRODUCTIONS
  );
  const canShowProductionPlan = getRemoteValueBoolean(
    RemoteConfigKey.SHOW_PRODUCTION_PLAN
  );

  const user = useAppSelector((state) => state.session.user.data);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const isAdmin = isSuperRole(user?.role as TypeOfRole);
  const isScheduleUser = canProductionSchedule(user?.role as TypeOfRole);
  const isScheduleUserOnlyView = canProductionScheduleOnlyView(
    user?.role as TypeOfRole
  );
  const showSchedule =
    isAdmin ||
    (isScheduleUser && canShowProductionPlan) ||
    (isScheduleUserOnlyView && canShowProductionPlan);

  const [tab, setTab] = useState<number>(-1);
  const [filteredProductions, setFilteredProductions] = useState<
    ProductionRecord[]
  >([]);
  const [usersByKitchen, setUsersByKitchen] = useState<User[]>([]);

  const [selectedProductionAndAction, setSelectedProductionAndAction] =
    useState<SelectedProductionAndAction>();

  const [renderCards, setRenderCards] = useState<React.ReactElement[]>([]);
  const [resetCards, setResetCards] = useState<number>(new Date().getTime());

  const cardsToRender = 8;
  const [maxRender, setMaxRender] = useState<number>(6);

  const productions = useAppSelector(
    (state) => state.productions.productionList.list
  );

  const productionsListStatus = useAppSelector(
    (state) => state.productions.productionList.status
  );

  useEffect(() => {
    if (kitchenId) {
      dispatch(getUsersByKitchenId({ kitchenId })).then((result) => {
        setUsersByKitchen(result.payload as User[]);
      });
    }
  }, [dispatch, kitchenId]);

  const filterTerm = (el: ProductionRecord) => {
    if (!searchTerm) return true;
    return (
      compareSearchText(el.name, searchTerm) ||
      compareSearchText(el.sku, searchTerm) ||
      compareSearchText(el.id, searchTerm)
    );
  };

  useEffect(() => {
    const filtered = productions?.filter(filterTerm) ?? [];
    setFilteredProductions(filtered);
    if (selectedProductionAndAction) {
      const currentProduction = filtered.find(
        (prod) => prod.id === selectedProductionAndAction.production.id
      );
      if (currentProduction) {
        setSelectedProductionAndAction({
          ...selectedProductionAndAction,
          production: currentProduction,
        });
      }
    }
  }, [productions, searchTerm]);

  const filterTimeSlot = (timeSlot: string) => {
    if (timeSlot === t(commons.ALL)) {
      setFilteredProductions(productions ?? []);
    } else {
      const filtered =
        productions?.filter(
          (el: ProductionRecord) => el.timeSlot === timeSlot
        ) ?? [];
      setFilteredProductions(filtered);
    }
  };

  const getProductions = useCallback(
    (status: "ACTIVES" | "FINISHED") => {
      if (!kitchenId) {
        return;
      }
      dispatch(getProductionsByKitchen({ kitchenId, status }));
    },
    [kitchenId]
  );

  const scrolling = (e: any) => {
    const scrollX = e.target.scrollLeft;
    const scrollWidth = e.target.scrollWidth;
    const clientWidth = e.target.clientWidth;
    const scrollThreshold = 50;
    const scrollEnd = scrollX + clientWidth >= scrollWidth - scrollThreshold;
    if (scrollEnd && maxRender < filteredProductions.length) {
      setMaxRender(maxRender + cardsToRender);
    }
  };

  useEffect(() => {
    setRenderCards([]);
    setMaxRender(cardsToRender);
    setResetCards(new Date().getTime());
  }, [filteredProductions]);

  const onRefresh = () => {
    if (tab === 1) {
      getProductions("ACTIVES");
    }
    if (tab === 2) {
      getProductions("FINISHED");
    }
  };

  useEffect(() => {
    const renderCardsTmp: React.ReactElement[] = [];
    const max =
      maxRender <= filteredProductions.length
        ? maxRender
        : filteredProductions.length;
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < max; index++) {
      const production = filteredProductions[index];
      renderCardsTmp.push(
        <ProductionCard
          key={`ProductionCard${production.id}`}
          production={production}
          kitchenId={kitchenId ?? null}
          setSelectedProductionAndAction={setSelectedProductionAndAction}
          onRefresh={onRefresh}
        />
      );
    }
    setRenderCards(renderCardsTmp);
  }, [maxRender, resetCards]);

  const [productionDialogProps, setProductionDialogProps] =
    useState<ProductionDialogProps>({ action: Actions.add });
  const { dialog, handleOpen, handleClose } = useHandleProductions(
    productionDialogProps
  );

  const [startPlus, setStartPlus] = useState<number>(0);
  const isProductionsList = [-1, 1, 2].includes(tab);
  const [refreshCalendar, setRefreshCalendar] = useState<number>(
    new Date().getTime()
  );

  const openAddProductionDialog = (props: ProductionDialogProps) => {
    setProductionDialogProps({
      ...props,
      refreshCalendar: () => {
        setRefreshCalendar(new Date().getTime());
        handleClose();
      },
    });
    handleOpen();
  };

  useEffect(() => {
    onRefresh();
  }, [tab]);

  return (
    <PageBox>
      <SearchOnTopBar onSearch={setSearchTerm} clean={tab} />
      <PageHeader
        title={t(kitchenInventoryManagement.PRODUCT_INVENTORY_TITLE)}
        subtitle={t(kitchenInventoryManagement.PRODUCT_INVENTORY_DESCRIPTION)}
        centerArea={
          <>
            {!isProductionsList && (
              <ProductionScheduleNavigation
                startPlus={startPlus}
                setStartPlus={setStartPlus}
              />
            )}
            {tab === 1 && <TimeSlotFilter onUpdate={filterTimeSlot} />}
          </>
        }
        rightArea={[
          {
            children: t(
              kitchenInventoryManagement.PRODUCT_INVENTORY_ADD_PRODUCTIONS
            ),
            onClick: () => {
              openAddProductionDialog({ action: Actions.add });
            },
            variant: "contained",
            size: "large",
            startIcon: <EventAvailableOutlinedIcon />,
            show: tab === 0,
          },
          {
            children: t(
              kitchenInventoryManagement.PRODUCT_INVENTORY_NEW_PRODUCTIONS
            ),
            onClick: () =>
              navigator.toByLocationType(`/productions/${kitchenId}/create`),
            variant: "contained",
            size: "large",
            startIcon: <TimerOutlinedIcon />,
            show: canCreateNewProductions && tab === 1,
          },
          {
            children: t(commons.UPDATE),
            variant: "outlined",
            startIcon: <RestartAltOutlinedIcon />,
            onClick: () => onRefresh(),
            show: isProductionsList,
          },
        ]}
      />

      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <KOSTabs
            tabs={[
              {
                children: {
                  text: t(
                    kitchenInventoryManagement.PRODUCT_INVENTORY_SCHEDULE_PRODUCTIONS
                  ),
                  startIcon: <CalendarMonthOutlinedIcon />,
                },
                hide: !showSchedule,
              },
              {
                children: {
                  text: t(
                    kitchenInventoryManagement.PRODUCT_INVENTORY_ACTIVE_PRODUCTIONS
                  ),
                  startIcon: <HistoryToggleOffOutlinedIcon />,
                },
              },
              {
                children: {
                  text: t(
                    kitchenInventoryManagement.PRODUCT_INVENTORY_ENDED_PRODUCTIONS
                  ),
                  startIcon: <FactCheckOutlinedIcon />,
                },
              },
            ]}
            onChange={setTab}
            loading={productionsListStatus === "loading"}
          />
        </Grid>
        {showSchedule && !isProductionsList && (
          <Grid item sm={12} xs={12} sx={{ overflowX: "auto" }}>
            <ProductionScheduleCalendar
              key={`ProductionScheduleCalendar-${refreshCalendar}`}
              startPlus={startPlus}
              openAddProductionDialog={openAddProductionDialog}
              kitchenId={kitchenId ?? ""}
              onlyView={isScheduleUserOnlyView}
            />
            {dialog}
          </Grid>
        )}
        {isProductionsList && (
          <>
            {productionsListStatus === "loading" && (
              <Grid item sm={12} xs={12}>
                <LinearProgress />
              </Grid>
            )}
            <Grid
              item
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                alignItems: "flex-start",
                overflowX: "auto",
                height: "calc(100vh - 250px)",
                pb: 1,
                gap: 2,
              }}
              onScroll={scrolling}
            >
              {productionsListStatus !== "loading" &&
                (!productions?.length || !filteredProductions.length) && (
                  <Box display="flex" sx={{ width: "100%" }}>
                    <Box sx={{ width: "100%", mx: "auto", my: 5 }}>
                      <KOSEmptyState
                        icon={InfoOutlinedIcon}
                        message={
                          !productions?.length
                            ? t(
                                kitchenInventoryManagement.PRODUCT_INVENTORY_MODAL_TITLE
                              )
                            : t(commons.NO_SEARCH_RESULTS, {
                                search: searchTerm,
                              })
                        }
                        instruction={
                          !productions?.length
                            ? t(
                                kitchenInventoryManagement.PRODUCT_INVENTORY_MODAL_INSTRUCTION
                              )
                            : undefined
                        }
                      />
                    </Box>
                  </Box>
                )}
              {renderCards}
              {selectedProductionAndAction?.action ===
                SelectedProductionActionType.DRAWER_INFO && (
                <ProductionInfo
                  production={selectedProductionAndAction.production}
                  kitchenId={kitchenId}
                  onClose={() => setSelectedProductionAndAction(undefined)}
                  setSelectedProductionAndAction={
                    setSelectedProductionAndAction
                  }
                  onRefresh={onRefresh}
                />
              )}
              {selectedProductionAndAction?.action ===
                SelectedProductionActionType.LABEL_HISTORY && (
                <ProductionLabelHistoryDialog
                  production={selectedProductionAndAction.production}
                  onClose={() => setSelectedProductionAndAction(undefined)}
                />
              )}
              {selectedProductionAndAction?.action ===
                SelectedProductionActionType.CREATE_LABEL && (
                <>
                  {!selectedProductionAndAction.production
                    .productionManager && (
                    <ProductionUserManager
                      production={selectedProductionAndAction.production}
                      users={usersByKitchen}
                      onClose={() => setSelectedProductionAndAction(undefined)}
                    />
                  )}
                  {!!selectedProductionAndAction.production
                    .productionManager && (
                    <ProductionLabelCreate
                      production={selectedProductionAndAction.production}
                      onClose={() => setSelectedProductionAndAction(undefined)}
                    />
                  )}
                </>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </PageBox>
  );
};

export default ProductionPage;
