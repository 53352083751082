import { KOSBaseTable, KOSBaseTableHeader } from "@foodology-co/alejandria";
import { Box } from "@mui/material";
import PageHeader from "app/components/PageHeader/PageHeader";
import { FunctionComponent } from "react";

interface Props {
  data: any[];
  loading: boolean;
  title: string;
  headers: KOSBaseTableHeader[];
}

const BcIntegrationTable: FunctionComponent<Props> = (props) => {
  const { data, loading, title, headers } = props;

  return (
    <Box>
      <PageHeader title={title} isFullDialog />

      <KOSBaseTable
        columns={headers}
        rows={{
          data: data ?? [],
          total: data.length,
          loading,
        }}
        pagination={{
          enable: true,
        }}
      />
    </Box>
  );
};
export default BcIntegrationTable;
