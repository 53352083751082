import { FdlgyCountry } from "config/accuracy";
import { Group } from "./Group";

export interface RawCounting {
  countingTypeCode: string;
  name: string;
  kitchenId: string;
  status: string;
  id: string;
  userId: string;
  createdAt: Date;
  endAt: string;
  scheduledDate: string;
  accuracy: number;
}

export interface CountingDetailData {
  countingId: number;
  sku: string;
  unit: string;
  quantity: number;
  status: string;
  theoreticalQuantity: number;
  productName: string;
  productGroup: string;
  country: FdlgyCountry;
}

export interface CountingAccuracyAndDetails {
  accuracy: number;
  details: CountingDetailData[];
}

export interface Counting {
  countingType: string;
  kitchenId: string;
  status: string;
  groups: Array<Group>;
}

export interface CountingRecord {
  id: string;
  countingType: string;
  kitchenId: string;
  status: string;
  groups?: Array<Group>;
  comment: string;
  validationMessage?: string;
}

export interface CountingExportDetail {
  kitchenId: string;
  countingId: number;
  sku: string;
  productName: string;
  theoreticalQuantity: number;
  quantity: number;
}

export interface CountingKitchenExportDetail {
  sku: string;
  quantity: string;
  productName: string;
}

export interface InventoryResponse {
  ok: boolean;
  message: string;
}

export interface CountingType {
  code: string;
  name?: string;
  countAll: boolean;
  reset: boolean;
}

export type MassiveData = Record<string, string[]>;

export interface MassiveDataToSent {
  kitchenId: string;
  skus: string[];
}

export interface CountingDetail {
  countingId: number;
  sku: string;
  productName: string;
  unit: string;
  quantity: number;
  comments?: string;
  validationMessage?: string;
  status: string;
  userId?: string;
  quantityAfterValidation?: number;
  validationBy?: string;
  validationAt?: string;
  lastTheoretical?: number;
  lastQuantity?: number;
}

export interface LastRecountResponse {
  success: boolean;
  data?: CountingResponse;
}

export interface CountingResponse {
  id: string;
  type: string;
  kitchenId: string;
  date: Date | null;
  userId: string;
  status: string;
  comment: string | null;
  countingDetails: CountingDetail[];
  scheduledDate: string;
}

export enum CountingStatusFilter {
  SCHEDULED = "SCHEDULED",
  IN_PROCESS = "IN_PROCESS",
  COMPLETED = "FINISHED",
}
