import { KOSEmptyState } from "@foodology-co/alejandria";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import LinearProgress from "@mui/material/LinearProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { commons, supplyManagement } from "app/i18n/types";
import { groupByKey } from "app/utils/array";
import { Supply, SupplyInactiveKitchen } from "core/supplies/entities/Supply";
import { FunctionComponent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { compareSearchText } from "utils/general";
import GreenSwitch from "../GreenSwitch";

interface ByKitchenTableProps {
  supplies: Supply[];
  inactiveSupplies: SupplyInactiveKitchen[];
  loading: boolean;
  searchText: string | null;
  onToggleInactive: (suppliesSkus: Array<string>, status: boolean) => void;
}

const ByKitchenTable: FunctionComponent<ByKitchenTableProps> = (props) => {
  const { supplies, loading, inactiveSupplies, onToggleInactive, searchText } =
    props;

  const { t } = useTranslation();

  const [openGroup, setOpenGroup] = useState<string | null>(null);

  const suppliesGroups = useMemo(() => {
    let filteredSupplies = supplies;

    return groupByKey(filteredSupplies, (supply) => supply.group);
  }, [supplies]);

  const toggleOpenGroup = useCallback(
    (key: string) => {
      if (openGroup === key) {
        setOpenGroup(null);
        return;
      }

      setOpenGroup(key);
    },
    [openGroup]
  );

  return (
    <Box pb={4}>
      {loading && <LinearProgress />}

      {Object.keys(suppliesGroups).map((suppliesGroup) => (
        <Box key={suppliesGroup} mb={0.125}>
          <Box
            bgcolor="#F1EFEF"
            px={4}
            py={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ cursor: "pointer" }}
            role="button"
            onClick={() => toggleOpenGroup(suppliesGroup)}
          >
            <Box display="flex" gap={2} alignItems="center">
              <Chip
                label={
                  searchText === null
                    ? suppliesGroups[suppliesGroup].filter(
                        (supply) =>
                          !inactiveSupplies.some(
                            (inactiveSupply) =>
                              supply.sku === inactiveSupply.sku
                          )
                      ).length
                    : suppliesGroups[suppliesGroup].filter(
                        (supply) =>
                          supply.name.includes(searchText) ||
                          supply.sku.includes(searchText)
                      ).length
                }
                sx={{
                  bgcolor: "white",
                  color: "primary.main",
                  fontWeight: 700,
                }}
              />

              <Typography textTransform="capitalize" fontWeight={700}>
                {suppliesGroup === ""
                  ? "Sin grupo"
                  : suppliesGroup.toLocaleLowerCase()}
              </Typography>
            </Box>

            <Box display="flex" gap={2} alignItems="center">
              <Box>
                {openGroup === suppliesGroup ? (
                  <ExpandLessOutlinedIcon />
                ) : (
                  <ExpandMoreOutlinedIcon />
                )}
              </Box>
            </Box>
          </Box>

          {openGroup === suppliesGroup && (
            <TableContainer component={Box}>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="counting-history"
                stickyHeader
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "& > th": {
                        borderBottom: "2px solid #F1EFEF",
                        color: "primary.main",
                        fontWeight: 700,
                      },
                    }}
                  >
                    <TableCell align="center">{t(commons.SKU)}</TableCell>
                    <TableCell align="left">{t(commons.NAME)}</TableCell>
                    <TableCell align="center">{t(commons.UNITS)}</TableCell>
                    <TableCell align="center">Disponibilidad Insumo</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {suppliesGroups[suppliesGroup]
                    .filter((supply) => {
                      if (!searchText) return true;
                      return (
                        compareSearchText(supply.name, searchText) ||
                        compareSearchText(supply.sku, searchText)
                      );
                    })
                    .map((supply) => (
                      <TableRow key={supply.sku}>
                        <TableCell align="center">
                          <Typography>{supply.sku}</Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography>{supply.name}</Typography>
                        </TableCell>

                        <TableCell align="center">
                          {supply.unit !== "" && (
                            <Typography>
                              {t(
                                `measureUnits.${supply.unit.toLocaleLowerCase()}`
                              )}
                            </Typography>
                          )}
                        </TableCell>

                        <TableCell align="center" width={200}>
                          <Box
                            display="flex"
                            gap={1}
                            alignItems="center"
                            flexBasis={160}
                            flexShrink={0}
                          >
                            <GreenSwitch
                              checked={
                                !inactiveSupplies.some(
                                  (inactiveSupply) =>
                                    supply.sku === inactiveSupply.sku
                                )
                              }
                              onChange={(_, checked) =>
                                onToggleInactive([supply.sku], checked)
                              }
                              inputProps={{ "aria-label": "controlled" }}
                            />

                            <Typography fontWeight={700} variant="body2">
                              {inactiveSupplies.some(
                                (inactiveSupply) =>
                                  supply.sku === inactiveSupply.sku
                              )
                                ? "Desactivado"
                                : "Activado"}
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}

                  {supplies.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={7}>
                        <KOSEmptyState
                          icon={InfoOutlinedIcon}
                          instruction={t(supplyManagement.EMPTY_STATE_INST)}
                          message={t(supplyManagement.EMPTY_STATE_MSG)}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default ByKitchenTable;
