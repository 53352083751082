export interface ProductionPlanSave {
  sku: string;
  quantity: number;
  scheduledDate: string;
  userAssigned: string;
  timeSlot: string;
}

export interface ProductionPlanUpdate {
  sku: string;
  quantity: number;
  scheduledDate: string;
  userAssigned: string;
  timeSlot: string;
  modifyComment: string;
  assignedUserName?: string;
}

export interface ProductionPlanSelectedSlot {
  scheduledDate?: string;
  timeSlot?: string;
}

export enum SlotEnum {
  AM = "AM",
  M = "M",
  PM = "PM",
}

export type SlotType = `${SlotEnum}`;

export interface ProductionPlan {
  id: number;
  sku: string;
  name: string;
  quantity: number;
  measureUnit: string;
  scheduledDate: string;
  timeSlot: SlotType;
  status: string;
  source: string;
  productionId: null;
  locationId: string;
  country: string;
  assignedUserId: string;
  assignedUserName: string;
  productionStatus?: string;
}

export interface UpdateDateAndSlotPayload {
  id: string;
  slot: string;
  scheduledDate: string;
}

export interface ProductionPlanWithDetail extends ProductionPlan {
  producedQuantity?: number;
  startDate?: Date;
  finishedAt?: Date;
}

export interface ProductionPlanWithDetailResponse {
  data?: ProductionPlanWithDetail;
}

export const ProductionPlanUpdateReason: { [key: string]: string[] } = {
  ARCHIVE: [
    "LACK_OF_SUPPLIES",
    "DAMAGED_EQUIPMENT",
    "LACK_OF_PERSONNEL",
    "WATER_RATIONING",
    "POOR_PLANNING",
    "SUGGESTED_TOO_HIGH",
  ],
  MORE: [
    "STOCKOUTS",
    "PRODUCTION_ABOVE_PLAN",
    "POOR_PLANNING",
    "SUGGESTED_TOO_LOW",
    "TRAINING",
    "INVENTORY_EXPIRATION",
    "LAUNCH",
  ],
  LESS: ["LACK_OF_SUPPLIES", "YIELD", "POOR_PLANNING", "SUGGESTED_TOO_HIGH"],
};
