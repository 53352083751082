import { CancelOutlined, TaskAlt } from "@mui/icons-material";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { productionModifyReason } from "app/i18n/types";
import { ProductionPlanUpdateReason } from "core/productions/entities/Plan";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  onChangeAction: (action: string) => void;
  type: "LESS" | "MORE" | "ARCHIVE";
  disabled?: boolean;
}

const ProductionModifyReasonSelect = (props: Props) => {
  const { onChangeAction, disabled, type } = props;

  const { t } = useTranslation();

  const [value, setValue] = useState<string>();
  const [options, setOptions] = useState<string[]>([]);
  const [typeOptions, setTypeOptions] = useState<string>();

  useEffect(() => setTypeOptions(type), [type]);
  useEffect(() => {
    if (!typeOptions) return;
    setOptions(ProductionPlanUpdateReason[typeOptions]);
  }, [typeOptions]);

  const handleIcon = (isError: boolean) => {
    if (isError) {
      return <CancelOutlined color="warning" />;
    }

    return <TaskAlt color="success" />;
  };

  return (
    <FormControl fullWidth>
      <InputLabel>{t(productionModifyReason.LABEL_SELECT)}</InputLabel>
      <Select
        label={t(productionModifyReason.LABEL_SELECT)}
        placeholder={t(productionModifyReason.LABEL_SELECT)}
        value={value}
        disabled={disabled}
        endAdornment={handleIcon(!value)}
        fullWidth
        onChange={(event: SelectChangeEvent<string>) => {
          const newValue = event.target.value;
          setValue(newValue);
          onChangeAction(newValue);
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={`ProductionModifyReasonSelect-${option}`}
            value={option}
          >
            {t(
              productionModifyReason[
                option as keyof typeof productionModifyReason
              ]
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ProductionModifyReasonSelect;
