import { KOSEmptyState } from "@foodology-co/alejandria";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import ContentPasteGoOutlinedIcon from "@mui/icons-material/ContentPasteGoOutlined";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import { Alert, CircularProgress, Grid, LinearProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import KitchenSelector from "app/components/Kitchen/Selector";
import PageBox from "app/components/PageBox";
import PageHeader from "app/components/PageHeader";
import TheoreticalInventoryComparisonCitiesTable from "app/components/TheoreticalInventory/TheoreticalInventoryComparisonCitiesTable";
import TheoreticalInventoryComparisonTable from "app/components/TheoreticalInventory/TheoreticalInventoryComparisonTable";
import InputSearchCustom from "app/components/common/InputSearchCustom";
import { useAllKitchens } from "app/hooks/useAllKitchens";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import {
  commons,
  kitchenInventoryManagement,
  theoricalInventoryModule,
} from "app/i18n/types";
import {
  getTheoreticalInventoryComaprisonByCity,
  getTheoreticalInventoryComaprisonByKitchen,
} from "app/store/slices/theoricalInventory/thunks";
import { convertDate } from "app/utils/dateUtils";
import { FdlgyCountry } from "config/accuracy";
import { AmplitudeEvent, logEvent } from "core/common/utils/analytics";
import { downloadTextAsCsv, json2Csv } from "core/common/utils/fileUtils";
import { KitchenSlice } from "core/kitchens/entities/Kitchen";
import { getdetailsByIds } from "core/physicalCount/repositories/http/counting";
import {
  KitchenCountingAccuracy,
  TheoreticalInventoryComparisonByKitchen,
} from "core/theoricalInventory/entities/ThereticalInventory";
import { comparisonByCountry } from "core/theoricalInventory/repositories/TheoreticalInventory";
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  compareSearchText,
  DataFilterTheoricalComparision,
  getAlertColor,
} from "utils/general";
import { getTheoricalInventoryConfig } from "utils/generalUI";

const TheoreticalInvenotrycomparisonReport: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const useKitchen = useAllKitchens();

  const comparisonRequest = useAppSelector(
    (state) => state.theoricalInventory.comaprisonByKitchen
  );

  const comparisonCityRequest = useAppSelector(
    (state) => state.theoricalInventory.comaprisonByCity
  );

  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  const [selectedCity, setSelectedCity] = useState<string | null>(null);
  const [selectedKitchen, setSelectedKitchen] = useState<KitchenSlice | null>(
    null
  );
  const [search, setSearch] = React.useState<string>("");
  const [data, setData] = useState<TheoreticalInventoryComparisonByKitchen[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);

  const [dataCities, setDataCities] = useState<KitchenCountingAccuracy[]>([]);
  const [dataFiltered, setDataFiltered] = useState<
    TheoreticalInventoryComparisonByKitchen[]
  >([]);
  const [dataCitiesFiltered, setDataCitiesFiltered] = useState<
    KitchenCountingAccuracy[]
  >([]);
  const [dataFilter, setDataFilter] =
    useState<DataFilterTheoricalComparision>("all");

  const onCountryChange = useCallback((country: string | null) => {
    logEvent(AmplitudeEvent.SelectCountryComparison, { country });
    setSelectedCountry(country);
  }, []);

  const onCityChange = useCallback((city: string | null) => {
    logEvent(AmplitudeEvent.SelectCityComparison, { city });
    setSelectedCity(city);
  }, []);

  const onKitchenChange = useCallback((kitchen: KitchenSlice | null) => {
    logEvent(AmplitudeEvent.SelectKitchenComparison, { kitchen });
    setSelectedKitchen(kitchen);
  }, []);

  const downloadCSV = async () => {
    setLoading(true);
    const ids = dataCities.map((kitchenAccuracy) =>
      Number(kitchenAccuracy.countingId)
    );
    await getdetailsByIds(ids)
      .then((response) => {
        downloadTextAsCsv(
          json2Csv(response),
          `inventory_difference_${selectedCountry}.csv`
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (comparisonRequest.status === "succeeded") {
      setData(comparisonRequest.data.data ?? []);
    }
  }, [comparisonRequest]);

  useEffect(() => {
    if (comparisonCityRequest.status === "succeeded") {
      setDataCities(comparisonCityRequest.data ?? []);
    }
  }, [comparisonCityRequest]);

  const filterValidate = (
    item: TheoreticalInventoryComparisonByKitchen,
    dataFilterParam: DataFilterTheoricalComparision
  ): boolean => {
    const config = getTheoricalInventoryConfig(
      item,
      (selectedCountry as FdlgyCountry) ?? FdlgyCountry.COL
    );

    const { percentage, exceptionValidator, percentageMin, percentageMax } =
      config;

    switch (dataFilterParam) {
      case "none":
        return exceptionValidator;
      case "zero":
        return percentage === 0 && !exceptionValidator;
      case "low":
        return (
          percentageMin <= percentage &&
          percentage <= percentageMax &&
          percentage !== 0 &&
          !exceptionValidator
        );
      case "high":
        return (
          (percentage < percentageMin || percentageMax < percentage) &&
          !exceptionValidator
        );
      default:
        return true;
    }
  };
  const searchValidate = (
    line: TheoreticalInventoryComparisonByKitchen,
    toSearch: string
  ): boolean => {
    if (!toSearch) return true;
    return (
      compareSearchText(line.sku, toSearch) ||
      compareSearchText(line.productName, toSearch)
    );
  };

  const searchCityValidate = (
    line: KitchenCountingAccuracy,
    toSearch: string
  ): boolean => {
    if (!toSearch) return true;
    return compareSearchText(line.name, toSearch);
  };

  useEffect(() => {
    logEvent(AmplitudeEvent.SearchProductComparison, { query: search });
    setDataFiltered(
      data.filter((line) => {
        return searchValidate(line, search) && filterValidate(line, dataFilter);
      })
    );
  }, [data, search]);

  useEffect(() => {
    logEvent(AmplitudeEvent.SearchLocationComparison, { query: search });
    setDataCitiesFiltered(
      dataCities.filter((line) => {
        return searchCityValidate(line, search);
      })
    );
  }, [dataCities, search]);

  const getComaprisonByKitchen = useCallback(() => {
    setData([]);
    if (!selectedKitchen) return;
    dispatch(getTheoreticalInventoryComaprisonByKitchen(selectedKitchen));
  }, [dispatch, selectedKitchen]);

  const getComaprisonByCity = useCallback(() => {
    setDataCities([]);
    if (!selectedCity) return;
    dispatch(
      getTheoreticalInventoryComaprisonByCity({ cityCode: selectedCity })
    );
  }, [dispatch, selectedCity]);

  const getComaprisonByCountry = () => {
    setDataCities([]);
    if (selectedCountry) {
      setLoading(true);
      comparisonByCountry(selectedCountry)
        .then((data) => {
          if (data.kitchens) {
            setDataCities(data.kitchens);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleChangeKitchen = useCallback(
    (kitchenId: string) => {
      setSelectedKitchen(useKitchen.byKitchenId(kitchenId) ?? null);
    },
    [useKitchen.list]
  );

  useEffect(() => {
    getComaprisonByCity();
  }, [dispatch, getComaprisonByCity, selectedCity]);

  useEffect(() => {
    getComaprisonByKitchen();
  }, [dispatch, getComaprisonByKitchen, selectedKitchen]);

  useEffect(() => {
    getComaprisonByCountry();
  }, [selectedCountry]);

  const update = () => {
    if (!!selectedCountry && !selectedCity && !selectedKitchen) {
      getComaprisonByCountry();
    }
    if (!!selectedCountry && !!selectedCity && !selectedKitchen) {
      getComaprisonByCity();
    }
    if (!!selectedCountry && !!selectedCity && !!selectedKitchen) {
      getComaprisonByKitchen();
    }
  };

  return (
    <PageBox>
      <PageHeader
        title={t(
          kitchenInventoryManagement.THEORETICALINVENTORYCOMPARISON_MODULE
        )}
        subtitle={t(
          kitchenInventoryManagement.THEORETICALINVENTORYCOMPARISON_MODULE_DESCRIPTION
        )}
        rightArea={[
          {
            children: t(commons.UPDATE),
            variant: "outlined",
            startIcon: <CloudSyncIcon />,
            onClick: update,
            disabled: comparisonRequest.status === "loading",
            sx: {
              bgcolor: "white",
            },
          },
          {
            children: t(commons.EXPORT),
            variant: "contained",
            startIcon: loading ? (
              <CircularProgress size={16} />
            ) : (
              <MoveToInboxIcon />
            ),
            onClick: downloadCSV,
            sx: {
              bgcolor: "primaty",
            },
            disabled: dataCities.length === 0,
            show: !selectedKitchen,
          },
        ]}
      />

      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item sm={8} xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {t(theoricalInventoryModule.REPORT_FILTER)}
            </Typography>
            <KitchenSelector
              selected={{
                country: selectedCountry ?? undefined,
                city: selectedCity ?? undefined,
                kitchen: selectedKitchen?.kitchenId ?? undefined,
              }}
              onChange={{
                country: (value) => onCountryChange(value?.code ?? ""),
                city: (value) => onCityChange(value?.code ?? ""),
                kitchen: (value) =>
                  onKitchenChange(value ? (value as KitchenSlice) : null),
              }}
              extra={{ showHash: true }}
            />
          </Paper>
        </Grid>
        <Grid item sm={4} xs={12}>
          {selectedKitchen !== null ? (
            <InputSearchCustom
              title={commons.FIND_INPUT}
              label={commons.FIND_SUPPLY_LABEL}
              setSearch={setSearch}
            />
          ) : (
            <InputSearchCustom
              title={commons.FIND_LOCATION_TITLE}
              label={commons.FIND_LOCATION_INPUT}
              setSearch={setSearch}
            />
          )}
        </Grid>
      </Grid>

      {comparisonRequest.status === "failed" && selectedCity === null && (
        <Box my={2}>
          <Alert severity="error">
            {t(theoricalInventoryModule.ERROR_DATA)}
          </Alert>
        </Box>
      )}

      {!!selectedKitchen &&
        comparisonRequest.status === "succeeded" &&
        comparisonRequest.data.lastCounting !== null && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              borderRadius: "10px",
              alignItems: "center",
            }}
          >
            <Alert
              icon={<InsertChartOutlinedIcon />}
              severity={getAlertColor(
                comparisonRequest.data.lastCounting.accuracy * 100
              )}
              sx={{
                bgcolor: "#F1EFEF",
                mb: 2,
              }}
            >
              <strong>{t(theoricalInventoryModule.RESULT)}:</strong>{" "}
              {t(theoricalInventoryModule.ACCURACY_RESULT, {
                accuracy: Math.round(
                  comparisonRequest.data.lastCounting.accuracy * 100
                ),
              })}
            </Alert>
            <Alert
              severity="info"
              sx={{
                bgcolor: "#E7F4FB",
                mb: 2,
              }}
            >
              <strong>{t(commons.SHOWING)}:</strong>{" "}
              {t(theoricalInventoryModule.LAST_COUNTING_LABEL, {
                date: comparisonRequest.data.lastCounting.endAt
                  ? convertDate(
                      comparisonRequest.data.lastCounting.endAt,
                      "dd/MM/yyyy"
                    )
                  : "--/--/----",
                time: comparisonRequest.data.lastCounting.endAt
                  ? convertDate(
                      comparisonRequest.data.lastCounting.endAt,
                      "hh:mm:ss a"
                    )
                  : "--:--:--",
              })}
            </Alert>
          </Box>
        )}

      {loading && <LinearProgress />}

      {selectedKitchen !== null && (
        <TheoreticalInventoryComparisonTable
          loading={comparisonRequest.status === "loading" || loading}
          items={dataFiltered}
          country={(selectedCountry as FdlgyCountry) ?? FdlgyCountry.COL}
          kitchenId={selectedKitchen?.kitchenId}
        />
      )}
      {selectedKitchen === null && selectedCity !== null && (
        <TheoreticalInventoryComparisonCitiesTable
          loading={comparisonCityRequest.status === "loading"}
          items={dataCitiesFiltered}
          handleChangeKitchen={handleChangeKitchen}
        />
      )}

      {selectedKitchen === null && selectedCity === null && (
        <KOSEmptyState
          icon={ContentPasteGoOutlinedIcon}
          message={t(commons.SELECT_OPTION)}
          instruction={t(commons.SELECT_COUNTRY_CITY_KITCHEN)}
        />
      )}
    </PageBox>
  );
};

export default TheoreticalInvenotrycomparisonReport;
