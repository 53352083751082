import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import NumberField from "app/components/common/Field/Number";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { commons, physicalCountModule } from "app/i18n/types";
import { getCountItemHistory } from "app/store/slices/counting/thunks";
import { convertDate } from "app/utils/dateUtils";
import { Product } from "core/physicalCount/entities/Product";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ItemCountHistorySkeleton from "./ItemCountHistorySkeleton";

interface Props {
  item: Product;
  onClose: () => void;
  onContinue: (item: Product, increment: number) => void;
}

const SumModal: FunctionComponent<Props> = (props) => {
  const { onClose, item, onContinue } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const countingId = useAppSelector((state) => state.counting.countingId);

  const [increment, setIncrement] = useState<number>(0);

  useEffect(() => {
    if (item.SKU && !item.history.items && countingId) {
      dispatch(
        getCountItemHistory({
          countingId: countingId.toString(),
          sku: item.SKU,
        })
      );
    }
  }, [countingId, dispatch, item.SKU, item.history.items]);

  return (
    <Dialog open onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle id="alert-dialog-title" sx={{ pb: 0 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="h5" color="primary.main" mb={1}>
              {t(physicalCountModule.HISTORIAL)}
            </Typography>
          </Box>
          <IconButton
            color="primary"
            aria-label="close"
            onClick={() => onClose()}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Typography color="black" gutterBottom>
          {item.name}.
        </Typography>

        {item.history.status === "loading" && <ItemCountHistorySkeleton />}

        {item.history.items && item.history.items.length > 0 && (
          <Box display="flex" flexDirection="column" gap={1} mb={2}>
            {item.history.items?.map((historyItem) => (
              <Box
                border="1px solid #D7D3D3"
                px={2}
                py={1}
                borderRadius={1}
                alignItems="center"
                justifyContent="space-between"
                display="flex"
                gap={4}
                key={historyItem.id}
              >
                <Box display="flex" gap={1}>
                  {historyItem.type === "SCANNED" && <DocumentScannerIcon />}
                  {historyItem.type === "DIRECT" && <CheckCircleOutlineIcon />}
                  {historyItem.type === "SUM" && <LibraryAddIcon />}

                  <Box>
                    <Typography fontWeight={300}>
                      {t(commons.QUANTITY)}
                    </Typography>
                    <Typography fontWeight={700}>
                      {historyItem.quantity} {item.unitShortName}.
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex" gap={1}>
                  <AccessTimeIcon />
                  <Box>
                    <Typography fontWeight={300}>{t(commons.HOUR)}</Typography>
                    <Typography fontWeight={700}>
                      {convertDate(historyItem.createdAt, "HH:mm:ss aa")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        )}

        {item.history.items && item.history.items?.length === 0 && (
          <Typography variant="body2" color="text.secondary" mb={2}>
            {t(commons.TABLE_EMPTY)}
          </Typography>
        )}

        <Box
          border="1px solid #D7D3D3"
          bgcolor="#F1EFEF"
          px={2}
          py={1}
          borderRadius={1}
          alignItems="center"
          justifyContent="center"
          display="flex"
          gap={4}
          mb={2}
        >
          <Box display="flex" gap={1}>
            <CalculateOutlinedIcon />
            <Box>
              <Typography fontWeight={300}>{t(commons.TOTAL)}</Typography>
              <Typography fontWeight={700}>
                {item.quantity} {item.unitShortName}.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Typography variant="h5" color="primary.main" gutterBottom>
          {t(physicalCountModule.ADD_QUANTITY)}
        </Typography>

        <Typography mb={3}>
          {t(physicalCountModule.ADD_QUANTITY_DESCRIPTION)}
        </Typography>

        <Box display="flex" alignItems="center" gap={1}>
          <Typography fontWeight={700}>
            {t(commons.TOTAL)}: {item.quantity} +
          </Typography>
          <NumberField
            name="value"
            value={increment}
            size="small"
            onChangeValue={(newValue) => setIncrement(newValue)}
            instantUpdate
          />
          <Typography fontWeight={700}>{item.unitShortName}.</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          size="large"
          variant="contained"
          onClick={() => {
            onContinue(item, increment);
            onClose();
          }}
          disabled={
            increment === 0 ||
            item.state.status === "loading" ||
            item.quantity + increment < 0
          }
          fullWidth
        >
          {item.state.status === "loading" && <CircularProgress size={32} />}
          {item.state.status !== "loading" && (
            <>
              {t(commons.SAVE)} <NavigateNextIcon />
            </>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SumModal;
