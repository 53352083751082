import {
  KOSEmptyState,
  KOSSelectedResult,
  KOSTabs,
} from "@foodology-co/alejandria";
import { FactCheckOutlined } from "@mui/icons-material";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import { Button, LinearProgress } from "@mui/material";
import Box from "@mui/material/Box";
import CommentDrawerWithFloatButton from "app/components/CommentArea/DrawerWithFloatButton";
import PageBox from "app/components/PageBox";
import PageHeader from "app/components/PageHeader";
import { TabPanel } from "app/components/PageTabs";
import { TransferAuthorizeConfirmDialog } from "app/components/TransferOrders/Authorize/ConfirmDialog";
import TransferOrdersMadeTable from "app/components/Transfers/TransferOrdersMadeTable";
import CommonDialog from "app/components/common/CommonDialog";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useNavigator } from "app/hooks/useNavigator";
import {
  commons,
  kitchenInventoryManagement,
  transferModule,
} from "app/i18n/types";
import {
  getPackedTransferOrders,
  getTransferOrderById,
} from "app/store/slices/transfer/thunks";
import { CommentTypeEnum } from "core/comment/entity";
import { downloadTextAsCsv, json2Csv } from "core/common/utils/fileUtils";
import {
  KitchenSelection,
  SelectedTransferOrder,
  TransferOrderLineRefined,
  TransferOrderResponse,
} from "core/transfer/entities/TransferOrder";
import dayjs from "dayjs";
import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import { useMount } from "react-use";
import AvgPaceIcon from "utils/icons/AvgPaceIcon";
import PrintReportTemplate from "../printReport";
import KitchenSelector from "./KitchenSelector";
import TransferOrdersReviewPackedTwoPage from "./reviewPackedDetail";

interface Props {}

const PackedTransferOrdersReview: FunctionComponent<Props> = () => {
  const navigator = useNavigator();
  const { kitchenId } = navigator.params();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const componentRef = React.useRef(null);

  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [kitchens, setKitchens] = useState<KitchenSelection[]>([]);
  const [selectedTransfer, setSelectedTransfer] = useState<number | null>(null);
  const [openSuccessDialog, setOpenSuccessDialog] = useState<boolean>(false);
  const [print, setPrint] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<KOSSelectedResult[]>([]);
  const [selectedTransferOrders, setSelectedTransferOrders] = useState<
    SelectedTransferOrder[]
  >([]);

  useMount(() => {
    getTransferOrders();
  });

  const handleExport = async () => {
    const headersMap: Record<string, string> = {
      createdAt: t(transferModule.CREATED_AT),
      destinationKitchenName: t(transferModule.DESTINATION),
      status: t(transferModule.STATUS),
      externalId: t(transferModule.ORDER_NO),
      userId: t(transferModule.USER_CREATE),
      receivedBy: t(transferModule.USER_RECIBE),
      receivedAt: t(transferModule.RECEIVED_AT),
      sku: t(transferModule.SKU),
      description: t(transferModule.DESCRIPTION),
      quantity: t(transferModule.QUANTITY),
      measureUnit: t(transferModule.UNIT),
    };

    const formatValue = (header: string, value: any) => {
      if (header === "createdAt" || header === "receivedAt") {
        return value ? dayjs(value).format("YYYY-MM-DD HH:mm:ss") : "";
      }
      if (header === "status") {
        return t(`status.${value}`);
      }
      return value ?? "";
    };
    const transferOrders: TransferOrderResponse[] = await Promise.all(
      selectedTransferOrders.map(async (selected) => {
        const response = await dispatch(
          getTransferOrderById({ transferId: selected.id.toString() })
        );
        return response.payload as TransferOrderResponse;
      })
    );
    const headers = Object.keys(headersMap).filter((key) =>
      Object.keys(selectedItems[0]).includes(key)
    );

    const data = selectedItems.flatMap((item) => {
      const order = transferOrders.find(
        (transferOrder) => transferOrder.externalId === item.externalId
      );

      if (!order) return [];
      return order.lines.map((line) => {
        const row = headers.reduce((acc, header) => {
          acc[headersMap[header]] = formatValue(header, item[header]);
          return acc;
        }, {} as Record<string, any>);
        row[headersMap.sku] = line.sku;
        row[headersMap.description] = line.name;
        row[headersMap.quantity] = line.quantity;
        row[headersMap.measureUnit] = line.measureUnit;
        return row;
      });
    });

    const csvContent = json2Csv(data);
    const fileName = `transfers_${new Date().toISOString()}.csv`;
    downloadTextAsCsv(csvContent, fileName);
  };

  const updateAfterDelete = (transferId: number) => {
    let tempkitchens = kitchens;
    const index = tempkitchens.findIndex(
      (element) => element.transferId === transferId
    );
    if (index > -1) {
      tempkitchens.splice(index, 1);
      setKitchens(tempkitchens);
      if (tempkitchens.length > 0) {
        setSelectedTransfer(kitchens[0].transferId);
      } else {
        setSelectedTransfer(null);
      }
    }
  };

  const updateLines = (
    transferId: number,
    lines: TransferOrderLineRefined[]
  ) => {
    let tempkitchens = kitchens;
    const newKitchens = tempkitchens.map((kitchen) => {
      if (kitchen.transferId === transferId) {
        return {
          ...kitchen,
          lines: lines.length,
        } as KitchenSelection;
      } else {
        return kitchen;
      }
    });
    setKitchens(newKitchens);
  };

  const getTransferOrders = async () => {
    if (kitchenId) {
      setLoading(true);
      const transferOrderResponse = (
        await dispatch(getPackedTransferOrders(kitchenId))
      ).payload as TransferOrderResponse[];
      setKitchens(getKitchenSelector(transferOrderResponse));

      if (!selectedTransfer && transferOrderResponse.length) {
        setSelectedTransfer(transferOrderResponse[0].id);
      }
      setLoading(false);
    }
  };

  const getKitchenSelector = (transfers: TransferOrderResponse[]) => {
    return transfers.map((transfer) => {
      return {
        kitchenId: transfer.destinationKitchenId,
        name: transfer.destinationKitchenName,
        lines: transfer.lines.length,
        transferId: transfer.id,
      } as KitchenSelection;
    });
  };

  const onConfirmTransferOrder = () => {
    setOpenSuccessDialog(true);
  };

  const closeConfirmDialog = () => {
    if (selectedTransfer) {
      updateAfterDelete(selectedTransfer);
      setOpenSuccessDialog(false);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      setPrint(false);
    },
  });

  return (
    <PageBox>
      {selectedTransfer && kitchenId && print && (
        <PrintReportTemplate
          ref={componentRef}
          originKitchenId={kitchenId}
          transferId={selectedTransfer}
          handlePrint={handlePrint}
        />
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <PageHeader
          title={t(kitchenInventoryManagement.TRANSFER_AUTHORIZATION_TITLE)}
          subtitle={t(
            kitchenInventoryManagement.TRANSFER_AUTHORIZATION_DESCRIPTION
          )}
          rightArea={[
            {
              children: t(commons.UPDATE),
              variant: "outlined",
              startIcon: <RestartAltOutlinedIcon />,
              onClick: getTransferOrders,
              show: tab === 0,
            },
            {
              children: t(commons.CONFIRM),
              startIcon: <FactCheckOutlinedIcon />,
              size: "large",
              variant: "contained",
              onClick: () => setOpenConfirmDialog(true),
              disabled: !selectedTransfer,
              show: tab === 0,
            },
          ]}
        />
        {selectedItems.length > 0 && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<FileDownloadOutlinedIcon />}
            onClick={handleExport}
          >
            {t(commons.EXPORT)}
          </Button>
        )}
      </Box>

      {selectedTransfer && kitchenId && (
        <CommentDrawerWithFloatButton
          sourceId={selectedTransfer.toString()}
          kitchenId={kitchenId}
          type={CommentTypeEnum.TRANSFER_ORDER}
        />
      )}

      {openConfirmDialog && (
        <TransferAuthorizeConfirmDialog
          transferId={selectedTransfer ?? 0}
          onClose={() => setOpenConfirmDialog(false)}
          onError={setError}
          onSuccess={onConfirmTransferOrder}
        />
      )}

      <Box sx={{ mb: 2 }}>
        <KOSTabs
          tabs={[
            {
              children: {
                text: t(transferModule.TO_SEND),
                startIcon: <AvgPaceIcon fontSize="24" />,
              },
            },
            {
              children: {
                text: t(transferModule.MADE),
                startIcon: <FactCheckOutlined width="24" />,
              },
            },
          ]}
          onChange={setTab}
        />
      </Box>

      <TabPanel value={tab} index={0}>
        {!loading && kitchens?.length === 0 && (
          <KOSEmptyState
            icon={InfoOutlinedIcon}
            message={t(transferModule.NOT_PENDIG)}
          />
        )}

        <Box
          sx={{ overflowX: "scroll", height: "calc(100vh - 287px)" }}
          gap={2}
          mt={2}
        >
          {loading && (
            <Box width="100%">
              <LinearProgress />
            </Box>
          )}
          <Box>
            <KitchenSelector
              kitchens={kitchens}
              selectedTransfer={selectedTransfer ?? 0}
              setSelectedTransfer={setSelectedTransfer}
            />
          </Box>
          {!loading && selectedTransfer && (
            <Box>
              <TransferOrdersReviewPackedTwoPage
                kitchenId={kitchenId ?? ""}
                transferId={selectedTransfer}
                updateDelete={updateAfterDelete}
                updateLines={updateLines}
              />
            </Box>
          )}
        </Box>
        <CommonDialog
          open={openSuccessDialog}
          title={t(transferModule.SUCCESS_AUTHORIZE_TITLE)}
          message={t(transferModule.SUCCESS_AUTHORIZE_DESCRIPTION)}
          icon={
            <CheckCircleOutlinedIcon
              sx={{
                height: "80px",
                width: "80px",
                paddingRight: "5px",
                color: "success.main",
              }}
            />
          }
          showCancelButton={true}
          handleConfirm={() => setPrint(true)}
          handleClose={closeConfirmDialog}
          cancelButtonLabel={t(commons.EXIT)}
          confirmButtonLabel={t(commons.PRINT)}
          isLoading={print}
        />

        <CommonDialog
          open={!!error}
          title={t(transferModule.ERROR_AUTHORIZE_TITLE)}
          message={error}
          icon={
            <DeleteOutlinedIcon
              sx={{
                height: "80px",
                width: "80px",
                paddingRight: "5px",
                color: "error.main",
              }}
            />
          }
          showCancelButton={false}
          handleConfirm={() => setError("")}
          handleClose={() => setError("")}
        />
      </TabPanel>

      <TabPanel value={tab} index={1}>
        <TransferOrdersMadeTable
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          onSelectedTransferOrders={setSelectedTransferOrders}
        />
      </TabPanel>
    </PageBox>
  );
};

export default PackedTransferOrdersReview;
